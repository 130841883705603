// We need jQuery
var $ = window.jQuery;

// Import Vue
// import Vue from 'vue/dist/vue.js';

// import VueX from 'vuex';

// import Store from '../Store';

// import CostOfLiving from '../../vue/CostOfLiving.vue';

// Vue.use(VueX);

export default {
  init() {
    // JavaScript to be fired on all pages
    // new Vue({
    //   store: new VueX.Store(Store),
    //   el: '#app',
    //   components: {
    //     CostOfLiving
    //   },
    //   created() {
    //     this.$store.dispatch('fetchPlacesData');
    //   },
    // });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
  loaded() {
    // Javascript to be fired on page once fully loaded
  },
};
